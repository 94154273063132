.autoScroll,
.autoScrollWithTabs {
  overflow-y: auto;
  overflow-x: hidden;
}
.autoScroll {
  height: 60vh;
}
.autoScrollWithTabs {
  max-height: 47vh;
}
.autoScrollWithDoubleHeadline {
  max-height: 54vh;
}

.autoScroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.autoScroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
