.swiper-pagination {
  bottom: 35px !important;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  opacity: 0.55;
  background: black;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: black;
}
.swiper-container {
  height: 100%;
}
