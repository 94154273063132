.fp-right {
  right: 0px !important;
}
.fp-slidesNav {
  text-align: center !important;
}
.fp-slidesNav.fp-bottom {
  bottom: 5px !important;
}
#fp-nav ul li .fp-tooltip {
  color: black;
}

.fp-right ul li a.active span,
.fp-slidesNav ul li a.active span {
  /*border: 1px solid white!important;*/
  height: 14px !important;
  width: 14px !important;
}

.fp-controlArrow {
  display: none;
}

/*fp nav points*/
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 7px;
  width: 7px;
  background: var(--grey-50);
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -4px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  height: 14px;
  width: 14px;
  margin: -7px 0 0 -7px;
  border-radius: 100%;
  background: #000;
}

.fp-section {
  overflow: hidden;
  /* border: 1px dashed rgb(255, 0, 179); */
}
