/*
fonts
*/
@font-face {
  font-family: Averta-Regular;
  src: url(../assets/fonts/Averta-Regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: Averta-Bold;
  src: url(../assets/fonts/Averta-Bold.woff);
  font-weight: bold;
}
@font-face {
  font-family: Averta-Extra-Bold;
  src: url(../assets/fonts/Averta-Extra-Bold.woff);
  font-weight: bolder;
}
@font-face {
  font-family: Averta-Semibold;
  src: url(../assets/fonts/Averta-Semibold.woff);
  font-weight: normal;
}
@font-face {
  font-family: Averta-Light;
  src: url(../assets/fonts/Averta-Light.woff);
  font-weight: normal;
}
@font-face {
  font-family: icon-font;
  src: url(../assets/fonts/busch-jaeger-icons.ttf);
  font-weight: normal;
}
@font-face {
  font-family: ABBvoice;
  src: url(../assets/fonts/abb/ABBvoice_Rg.ttf);
  font-weight: normal;
}
@font-face {
  font-family: ABBvoice-Bold;
  src: url(../assets/fonts/abb/ABBvoice_Bd.ttf);
  font-weight: bold;
}
@font-face {
  font-family: ABBvoice-Semibold;
  src: url(../assets/fonts/abb/ABBvoice_Md.ttf);
  font-weight: bold;
}
@font-face {
  font-family: ABBvoice-Light;
  src: url(../assets/fonts/abb/ABBvoice_Lt.ttf);
  font-weight: bold;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Averta-Regular", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
  overflow: auto;
}

a,
a:visited,
a:hover {
  outline: none;
  text-decoration: none !important;
  color: inherit;
}

@import url(colors.css);
@import url(fullpage.css);
@import url(mui.css);
@import url(swiper.css);
@import url(toast.css);
@import url(scroll.css);
@import url(playbook.css);
