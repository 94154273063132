:root {
  --bje-primary: #00acec;
  --bje-primary-black: #000000;
  --abb-primary: #ff000f;
  --abb-primary-white: #ffffff;

  --grey-10: #ebebeb;
  --grey-20: #dbdbdb;
  --grey-30: #bababa;
  --grey-40: #9f9f9f;
  --grey-50: #868686;
  --grey-60: #696969;
  --grey-70: #464646;
  --grey-80: #333333;
  --grey-90: #1f1f1f;
  --grey-100: #0f0f0f;
}
